jQuery(document).ready(function($) {
  console.log("video js", $("#video-01"));

  var index = null;
  var timer = null;
  var active_video = null;
  var video_listener = null;
  var total_video = $(".features-list-video li").length;

  $(".features-list li").on("click", function() {
    var _id = $(this).data("index");
    if (index == _id) {
      return;
    }

    clearAllClass();
    index = _id;
    changeVideoContent(_id);
  });

  function changeVideoContent(video_id) {
    // console.log("changeVideoContent", video_id);

    if (active_video) {
      active_video[0].removeEventListener("timeupdate", video_listener, false);
    }

    $(".features-section ul li.active").removeClass("active");

    $(".features-list li")
      .eq(video_id)
      .addClass("active");
    $(".features-list-video li")
      .eq(video_id)
      .addClass("active");

    var _video = $(".features-list-video li")
      .eq(video_id)
      .find("video");
    var _duration = $(_video).data("duration");

    startLoop(_video, _duration);
  }

  function startLoop(video, duration) {
    // console.log("startLoop", duration);

    $(video).hide();
    $(video).fadeIn();
    
    // for remove event listener
    active_video = video;

    video_listener = function() {
      const currentTimeMS = video[0].currentTime * 1000;
      const percent = Math.ceil((100 * currentTimeMS) / duration);
      clearAllClass();
      $(".features-section ul li.active .loader-circle").addClass(
        "progress-" + percent
      );
    };

    video[0].currentTime = 0;
    video[0].play();

    video[0].addEventListener("timeupdate", video_listener, false);

    clearTimeout(timer);

    timer = setTimeout(function() {
      index++;
      if (index >= total_video) {
        index = 0;
      }
      changeVideoContent(index);
    }, duration);
  }

  function clearAllClass() {
    $(".features-section ul li.active .loader-circle").attr("class", (i, c) =>
      c.replace(/(^|\s)progress-\S+/g, "")
    );
  }
});
