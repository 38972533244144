"use strict";

jQuery(document).ready(function($) {
  console.log("main.js");

  $("footer").addClass("open");

  var $cookie = getCookie("aa-cookie");
  if (!$cookie) {
    $(".cookie-alert").addClass("open");
  }

  $(".js-cookie-close").on("click", function(e) {
    e.preventDefault();
    console.log("js-cookie-close");
    setCookie("aa-cookie", "accept", 30);

    $(".cookie-alert").fadeOut(300, function() {
      $(this).remove();
    });
  });

  $(".back-to-top").on("click", function(e) {
    e.preventDefault();
    scrollToAnchor($(this).attr("href"));
  });

  $("[data-fancybox]").each(function() {
    $(this).fancybox({
      iframe: {
        css: {
          width: "600px"
        }
      },
      vimeo: {
        color: "4dca78"
      }
    });
  });

  setTimeout(() => {
    window.scrollTo(window.scrollX, window.scrollY - 5);
    window.scrollTo(window.scrollX, window.scrollY + 5);
  }, 300);

  var sections = $(".is-active-section"),
    nav = $(".site-header"),
    nav_height = nav.outerHeight();

  $(window).on("scroll", function() {
    var cur_pos = $(this).scrollTop();
    var service_pos =
      $(".services-section").offset().top -
      $(".services-section").outerHeight();

    if (cur_pos < 200) {
      console.log("hide footer");
      $("footer").removeClass("open");
    } else {
      $("footer").addClass("open");
    }

    sections.each(function() {
      var top = $(this).offset().top - (nav_height + 30);
      var bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        // if (cur_pos >= top) {
        nav.find(".menu-item a").removeClass("is-active");
        nav.find('a[href="#' + $(this).attr("id") + '"]').addClass("is-active");
      }
    });

    if (cur_pos >= service_pos) {
      // if (cur_pos >= top) {
      nav.find(".menu-item a").removeClass("is-active");
      nav.find('a[href="#services"]').addClass("is-active");
    }

    // if( )
  });

  function aboutUsContent() {
    console.log("aboutUsContent");
    setTimeout(function() {
      $(".about-section-content-holder").css(
        "margin-top",
        -1 * ($(".intro-video-section").outerHeight() / 2 - 75)
      );
    }, 100);
  }

  $(".hamburger").on("click", function() {
    $("#site-holder").toggleClass("is-active");
    $(this).toggleClass("is-active");
  });

  function mobileCarousel() {
    if ($(window).width() <= 812) {
      $(".owl-carousel").each(function() {
        var nav = $(this).data("nav");
        var items = parseInt($(this).data("items"), 10);
        var loop = $(this).data("loop");
        var stagePadding = parseInt($(this).data("stage-padding"), 10);
        console.log("stagePadding", stagePadding);
        var margin = items > 1 ? 40 : 20;
        $(this).owlCarousel({
          items: items,
          loop: loop,
          margin: margin,
          nav: nav,
          stagePadding: stagePadding,
          dots: false,
          navText: [
            "<img src='assets_home/images/left-arrow.svg'>",
            "<img src='assets_home/images/right-arrow.svg'>"
          ]
        });
      });
    } else {
      $(".owl-carousel")
        .trigger("destroy.owl.carousel")
        .removeClass("owl-carousel owl-loaded");
    }
  }

  $('.site-header nav a[href^="#"]').on("click", function(e) {
    e.preventDefault();

    if ($(window).width() <= 1024) {
      $(".hamburger").click();
    }

    // $(".site-header a.is-active").removeClass("is-active");

    scrollToAnchor($(this).attr("href"));

    return false;
  });

  function scrollToAnchor(id_name) {
    console.log(id_name);
    var _scrollTop =
      $(id_name).offset().top - ($(".site-header").outerHeight() + 20);
    $("html,body").animate({ scrollTop: _scrollTop }, 800, "swing");
  }

  $(window).on("scroll", function() {
    if ($(".features-section").is(":in-viewport")) {
      if (!$(".features-list").hasClass("in-view")) {
        setTimeout(() => {
          console.log("featured list in view");
          $(".features-list li")
            .eq(0)
            .click();
        }, 100);
      }
    }

    $(".check-in-view").each(function() {
      if (!$(this).hasClass("in-view")) {
        // console.log("check");
        if ($(this).is(":in-viewport")) {
          $(this).addClass("in-view");
        }
      }
    });
  });

  mobileCarousel();
  aboutUsContent();

  $(".site-content").css("marginBottom", $(".site-footer").outerHeight());

  $(window).resize(function() {
    $(window).trigger("scroll");
    if ($(window).width() <= 812) {
      mobileCarousel();
    }
    aboutUsContent();
    $(".site-content").css("marginBottom", $(".site-footer").outerHeight());
  });

  $(".newsletter-input").on("keyup", function() {
    var _value = $(this).val();
    var _result = validateEmail(_value);
    // console.log(_value, _result);
    if (_result) {
      $(this)
        .parent()
        .addClass("valid");

      $(this).keypress(function(e) {
        // Enter pressed?
        if (e.which == 10 || e.which == 13) {
          $(".submit-btn").click();
        }
      });
    } else {
      $(this)
        .parent()
        .removeClass("valid");
    }
  });

  var _sending = false;

  $(".submit-btn").on("click", function(e) {
    e.preventDefault();

    $(".mailchimp-response").hide();
    var _email = $(".newsletter-input").val();
    if (!_sending) {
      // console.log("submit-btn", _email);
      _sending = !_sending;
      $.post("newsletter.php", { email: _email }, function(result) {
        var _result = jQuery.parseJSON(result);
        console.log("result", _result);
        $(".mailchimp-response").text(_result.message);
        $(".mailchimp-response").fadeIn();

        _sending = false;
      });
    }
  });

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
});
